<template>
  <div>
    <h1 class="text-primary title-patternOne">
      Digite os dados do responsável
    </h1>
    <b-form @submit.prevent="register()" class="form-withPadding">
      <b-form-group>
        <b-form-input
          id="name"
          type="text"
          placeholder="Nome completo*"
          size="lg"
          required
          v-model="formRegister.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input
          id="email"
          type="email"
          placeholder="E-mail*"
          size="lg"
          required
          v-model="formRegister.email"
          @keypress="preventWhiteSpace($event)"
        ></b-form-input>
      </b-form-group>
      <b-form-row>
        <b-col cols="12" md="6">
          <b-form-group class="passwordInput-container">
            <b-form-input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Senha*"
              size="lg"
              required
              v-model="formRegister.password"
              class="input-withIconRight"
              @keypress="preventWhiteSpace($event)"
            ></b-form-input>
            <div>
              <b-icon
                icon="eye-slash-fill"
                class="color-gray1 icon-password"
                @click="showOrHidePassword()"
                v-if="showPassword"
              ></b-icon>
              <b-icon
                icon="eye-fill"
                class="color-gray1 icon-password"
                @click="showOrHidePassword()"
                v-else
              ></b-icon>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group class="passwordInput-container">
            <b-form-input
              id="confirm-password"
              :type="showConfirmPassword ? 'text' : 'password'"
              placeholder="Confirmar senha*"
              size="lg"
              required
              v-model="formRegister.confirmPassword"
              class="input-withIconRight"
              @keypress="preventWhiteSpace($event)"
            ></b-form-input>
            <div>
              <b-icon
                icon="eye-slash-fill"
                class="color-gray1 icon-password"
                @click="showOrHideConfirmPassword()"
                v-if="showConfirmPassword"
              ></b-icon>
              <b-icon
                icon="eye-fill"
                class="color-gray1 icon-password"
                @click="showOrHideConfirmPassword()"
                v-else
              ></b-icon>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <error-message :error="feedbackGeneralError" />
      <b-form-group>
        <b-button
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="loadingButton"
          ><b-spinner large v-if="loadingButton"></b-spinner
          ><span v-else>Avançar</span></b-button
        >
      </b-form-group>
    </b-form>
    <p class="text-primary text-patternOne">
      <span v-b-modal.modal-explainData>
        <b-icon icon="question-circle-fill" class="text-primary"></b-icon
        ><a class="ml-2">Por que pedimos esses dados?</a>
      </span>
    </p>
    <b-modal id="modal-explainData" centered :hide-footer="true">
      <explain-data />
    </b-modal>
  </div>
</template>

<script>
import ExplainData from './modals/ExplainData.vue';
export default {
  components: {
    ExplainData,
  },
  data: () => ({
    loadingButton: false,
    formRegister: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    showPassword: false,
    showConfirmPassword: false,
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    register() {
      if (this.formRegister.password != this.formRegister.confirmPassword) {
        this.feedbackGeneralError.message = [];
        this.feedbackGeneralError.message.push(
          'As senhas digitadas são diferentes.'
        );
      } else {
        let formData = {
          email: this.formRegister.email,
          name: this.formRegister.name,
          password: this.formRegister.password,
          explicitAcceptanceOfTermsOfUse: true
        }
      this.loadingButton = true;
      this.$onboardingService.register(formData)
        .then((response) => {
          this.$store.state.accountId = response.data.accountId;
          localStorage.setItem('account_id', response.data.accountId);
          localStorage.removeItem('totalCodeTimeValidateEmail');
          this.$router.push('/validate-email');
        })
        .catch((error) => {
          this.feedbackGeneralError = error;
          this.loadingButton = false;
        });
      }
    },
    showOrHidePassword() {
      this.showPassword = !this.showPassword;
    },
    showOrHideConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    preventWhiteSpace(event) {
      let key = event.keyCode;
      if (key === 32) event.preventDefault();
    },
  },
  created() {
    if (this.$store.state.emailVerified)
      this.formRegister.email = this.$store.state.emailVerified;
    
    localStorage.removeItem('account_token');
  },
};
</script>

<style lang="scss" scoped>
h1.text-primary.title-patternOne {
  margin-bottom: 1.5rem;
}

form {
  .btn {
    margin-top: 3rem;
  }
}

p {
  display: flex;
  justify-content: flex-end;
  > span {
    display: flex;
    align-items: center;
    width: fit-content;
    @media (min-width: 769px) {
      overflow: visible;
      position: absolute;
      right: 2rem;
    }
    &:focus {
      outline: 0;
    }
    .b-icon {
      font-size: 40px;
    }
    a {
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  form {
    .btn {
      margin-top: 1.5rem;
    }
  }
  p {
    justify-content: center;
  }
}
</style>