<template>
  <div>
    <b-container fluid="md" class="text-center">
      <b-row class="justify-content-lg-center">
        <b-col cols="12">
          <b-icon icon="person-check-fill" class="text-primary"></b-icon>
          <h1 class="text-primary title-patternOne">
            Por que pedimos esses dados?
          </h1>
          <p class="text-patternTwo color-black3">
            A Educacross usa essas informações para autenticar sua identidade,
            confirmar a veracidade dos dados e enviar comunicados. Esses dados
            serão armazenados de maneira segura e tratados de acordo com a nossa
            Política de Privacidade.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-3">
          <b-button
            type="submit"
            variant="secondary"
            size="lg"
            @click="hideModal()"
            >Fechar</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-explainData');
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  .col-12 {
    .b-icon {
      font-size: 170px;
      @media (max-width: 768px) {
        font-size: 120px;
      }
    }
    p {
      text-transform: uppercase;
    }
    .btn {
      width: 22rem;
    }
    p,
    .btn {
      margin-top: 2rem;
    }
  }
}
</style>